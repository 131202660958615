@import "settings/all";
@import "utilities/all";
@import "typography_fluid";

.bt-input {
  @apply flex flex-col items-start justify-start max-w-full relative;
  min-width: get-vw(235px);
  gap: get-vw(12px);

  @include mobile {
    @apply min-w-[230px] gap-3;
  }

  &.disable-input {
    @apply opacity-[0.4] pointer-events-none;
  }

  &.bt-textarea {
    min-width: get-vw(353px);

    @include mobile {
      @apply min-w-[353px];
    }
  }

  input {
    @include mobile {
      @apply h-10 leading-10;
    }
  }

  label {
    @apply leading-5 text-bt-matte-black;
  }

  .multi-lang-phone {
    @apply block w-full border border-solid border-bt-gray-02 outline-none shadow-none ring-0;
    height: get-vw(46px);
    line-height: get-vw(44px);
    border-radius: get-vw(100px);
    padding: 0 get-vw(16px);

    &:hover {
      @apply border-bt-matte-black;
    }

    @include mobile {
      @apply h-[38px] leading-[38px] rounded-[100px] px-4 py-0;
    }

    label {
      @apply hidden;
    }

    .country-selector {
      @apply inset-auto p-0 w-auto;
    }

    .mat-mdc-focus-indicator {
      @apply hidden;
    }

    .mat-mdc-button {
      .mat-mdc-button-persistent-ripple {
        @apply hidden;
      }

      .mat-mdc-button-touch-target {
        @apply top-0 translate-y-0;
        height: get-vw(44px);

        @include mobile {
          @apply h-9;
        }
      }
    }

    .mdc-button__label {
      margin-right: get-vw(16px);

      @include mobile {
        @apply mr-4;
      }
    }

    .country-selector-flag {
      @apply filter-none;
    }

    input {
      @apply outline-none ring-0 text-bt-matte-black;
      &:not(.country-search) {
        @apply pr-0;
      }
    }
  }

  .mat-mdc-form-field {
    @apply block h-auto;
    &.mat-focused {
      input,
      &.bt-textarea-wrap {
        @apply border-bt-matte-black;
      }

      .mat-mdc-select {
        @apply border-bt-matte-black;
        .mat-mdc-select-arrow {
          &:before {
            @apply -rotate-[135deg];
          }
        }
      }
    }

    &:not(.mat-form-field-no-animations) {
      .mdc-text-field__input {
        @apply transition-all duration-300 ease-in;
      }
    }

    &.bt-textarea-wrap {
      @apply border border-solid border-bt-gray-02 transition-all ease-in duration-300;
      min-height: get-vw(110px);
      padding-right: get-vw(10px);
      padding-bottom: get-vw(10px);
      border-radius: get-vw(24px);

      @include mobile {
        @apply min-h-24 rounded-2xl pr-2.5 pb-2.5;
      }
    }

    &,
    .mat-mdc-form-field-infix {
      @apply w-full;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      @apply bg-transparent;
    }

    .mdc-text-field,
    .mat-mdc-form-field-infix {
      @apply p-0;
    }

    .mat-mdc-form-field-infix {
      min-height: auto;
    }

    .mdc-line-ripple,
    .mat-mdc-form-field-focus-overlay {
      @apply hidden;
    }

    .mat-mdc-text-field-wrapper {
      @apply outline-none;
    }

    input,
    .mat-mdc-select,
    &.bt-textarea-wrap {
      @apply w-full border border-solid border-bt-gray-02;
      &:hover {
        @apply border-bt-matte-black;
      }
    }

    input,
    .mat-mdc-select {
      @apply outline-none shadow-none ring-0;
      border-radius: get-vw(100px);
      padding: 0 get-vw(16px);

      @include mobile {
        @apply rounded-[100px] px-4 py-0;
      }
    }

    input {
      height: get-vw(46px);
      line-height: get-vw(46px);

      @include mobile {
        @apply h-[38px] leading-[38px];
      }
    }

    .mat-mdc-select {
      height: get-vw(48px);
      line-height: get-vw(48px);

      @include mobile {
        @apply h-10 leading-10;
      }
    }

    .mat-mdc-form-field-textarea-control {
      min-height: get-vw(100px);
      padding: get-vw(13px) get-vw(6px) get-vw(3px) get-vw(16px);

      @include mobile {
        @apply min-h-[86px] pt-[13px] pr-[6px] pb-[3px] pl-4;
      }
    }

    .mat-mdc-form-field-error-wrapper {
      line-height: get-vw(20px);

      @include mobile {
        @apply leading-5;
      }
    }

    .mat-mdc-select {
      @apply transition-all duration-300 ease-in;
      &:hover {
        .mat-mdc-select-arrow {
          &:before {
            @apply rotate-[-135deg];
          }
        }
      }

      .mat-mdc-select-arrow {
        @apply flex items-center justify-center relative;
        width: get-vw(18px);
        height: get-vw(18px);

        @include mobile {
          @apply w-[18px] h-[18px];
        }

        &:before {
          @apply content-[''] bg-transparent block border border-solid border-bt-matte-black border-t-0 border-l-0 rotate-45
          transition-all duration-500 ease-in;
          width: get-vw(6px);
          height: get-vw(6px);
          margin-top: get-vw(-4px);

          @include mobile {
            @apply -mt-1 w-1.5 h-1.5;
          }
        }

        svg {
          @apply hidden;
        }
      }
    }

    .mat-mdc-select-placeholder {
      @apply text-bt-matte-black opacity-50;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    @apply absolute;
    bottom: get-vw(-20px);

    @include mobile {
      @apply -bottom-5;
    }
  }

  .mdc-evolution-chip-set {
    .mdc-evolution-chip-set__chips {
      @apply ml-0 gap-y-0;
      column-gap: get-vw(5px);

      @include mobile {
        @apply gap-x-[5px];
      }
    }

    .mdc-evolution-chip {
      @apply m-0 bg-bt-matte-black/5;
      border-radius: get-vw(100px);
      height: get-vw(21px);

      @include mobile {
        @apply h-[21px] rounded-[100px];
      }
    }
  }

  .mat-mdc-standard-chip {
    &.mdc-evolution-chip--with-trailing-action {
      .mdc-evolution-chip__action--primary {
        padding-left: get-vw(5px);

        @include mobile {
          @apply pl-[5px];
        }
      }

      .mdc-evolution-chip__action--trailing {
        @apply py-0 text-bt-matte-black opacity-100;
        padding-left: get-vw(5px);
        padding-right: get-vw(5px);

        @include mobile {
          @apply px-[5px];
        }
      }
    }

    .mdc-evolution-chip__icon--trailing {
      font-size: get-vw(15px);
      width: get-vw(15px);
      height: get-vw(15px);

      @include mobile {
        @apply text-[15px] w-[15px] h-[15px];
      }
    }
  }

  .mat-mdc-chip {
    &:hover {
      .mat-mdc-chip-focus-overlay {
        @apply opacity-100 bg-bt-matte-black/5;
      }
    }
  }
}

.bt-checkbox {
  @apply relative flex flex-row items-center justify-start bg-bt-pure-white;

  &:hover {
    input {
      @apply border-bt-matte-black;
    }
  }

  &.failed {
    .error {
      @apply opacity-100 visible translate-y-0;
    }
  }

  label {
    margin-left: get-vw(10px);

    @include mobile {
      @apply ml-2.5;
    }
  }

  input {
    @apply border border-solid border-bt-gray-02 appearance-none transition-all duration-300 ease-in text-transparent;
    width: get-vw(24px);
    height: get-vw(24px);
    border-radius: get-vw(6px);

    @include mobile {
      @apply w-6 h-6 rounded-md;
    }

    &:checked {
      @apply border-bt-matte-black;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>');
    }
  }

  .error {
    @apply absolute left-0 -z-[1] text-[#f31c1c] invisible opacity-0 -translate-y-full transition-all duration-300 ease-in;
    bottom: get-vw(-20px);

    @include mobile {
      @apply -bottom-5;
    }
  }
}

.cdk-overlay-pane {
  font-size: get-vw(15px);
  line-height: get-vw(21px);
  margin-top: get-vw(10px);
  margin-bottom: get-vw(10px);

  @include mobile {
    @apply text-[15px] leading-[21px] mt-2.5 mb-2.5;
  }

  &:not(.mat-mdc-select-panel-above),
  &.mat-mdc-select-panel-above {
    div.mat-mdc-select-panel {
      @apply px-0 border border-solid border-bt-matte-black;
      border-radius: get-vw(24px) !important;
      padding-top: get-vw(15px);
      padding-bottom: get-vw(15px);

      @include mobile {
        @apply py-[15px];
        border-radius: 24px !important;
      }
    }
  }

  .mat-mdc-option {
    min-height: get-vw(33px);

    @include mobile {
      @apply min-h-[33px];
    }

    &:hover {
      &:not(.mdc-list-item--disabled) {
        @apply bg-transparent;
      }
    }

    &:focus {
      .mdc-list-item {
        @apply bg-transparent;
      }
    }

    &.mat-mdc-option-active {
      &.mdc-list-item {
        @apply bg-transparent;
      }
    }

    &.mdc-list-item--selected {
      &:not(.mdc-list-item--disabled) {
        &:not(.mat-mdc-option-multiple) {
          @apply bg-transparent;
        }

        .mdc-list-item__primary-text {
          @apply text-bt-matte-black;
        }
      }
    }

    .mdc-list-item__primary-text {
      @apply text-bt-matte-black;
    }

    .mat-pseudo-checkbox-minimal,
    .mat-pseudo-checkbox {
      @apply hidden;
    }
  }
}

.cdk-overlay-dark-backdrop {
  @apply bg-bt-matte-black/70 #{!important};
}

.bt-file-upload {
  @include bt-caption;
  @apply flex flex-col items-center text-bt-matte-black w-full border border-dashed border-bt-gray-02 transition-all duration-500;
  border-radius: get-vw(24px);
  padding-top: get-vw(24px);
  padding-bottom: get-vw(24px);

  @include mobile {
    @apply rounded-3xl py-6;
  }

  &.active {
    @apply border-bt-deau-blue bg-bt-deau-blue;
  }
}
