@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content;
  }  
}

@mixin mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin mobile-only {
  @media screen and (max-width: $mobile - 1px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet), print {
   @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}

@mixin widescreen-only {
  @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
    @content;
  }
}

@mixin fullhd {
  @media screen and (min-width: $fullhd) {
    @content;
  }
}

@mixin bigtabletonly{
  @media screen and (min-width: 767.7px) and (max-width: 1199px){
    @content;
  }
}

@mixin tabletonly{
  @media screen and (min-width: 767.7px) and (max-width: 1025px){
    @content;
  }
}

@mixin bigmobile{
  @media screen and (max-width: 767.5px){
    @content;
  }
}

@mixin midmobile{
  @media screen and (max-width: 424px){
    @content;
  }
}

@mixin smobile{
  @media screen and (max-width: 374px){
    @content;
  }
}