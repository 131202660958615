// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$bt24-cms-frontend-primary: mat.define-palette(mat.$indigo-palette);
$bt24-cms-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$bt24-cms-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bt24-cms-frontend-theme: mat.define-light-theme(
    (
      color: (
        primary: $bt24-cms-frontend-primary,
        accent: $bt24-cms-frontend-accent,
        warn: $bt24-cms-frontend-warn
      )
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bt24-cms-frontend-theme);

/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Satoshi", "sans-serif";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Satoshi-Regular.woff2") format("woff2"),
  url("fonts/Satoshi-Regular.woff") format("woff"),
  url("fonts/Satoshi-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Satoshi", "sans-serif";
  font-style: normal;
  font-weight: 500;
  src: url("fonts/Satoshi-Medium.woff2") format("woff2"),
  url("fonts/Satoshi-Medium.woff") format("woff"),
  url("fonts/Satoshi-Medium.ttf") format("truetype");
}

@import "settings/all";
@import "utilities/all";
@import "typography_fluid";
@import "colors";
@import "buttons";
@import "inputs";
@import "keen-slider/keen-slider";

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

html {
  @include until(992px) {
    @apply overflow-x-hidden;
  }
}

body {
  @apply text-bt-matte-black;
  @include until(992px) {
    @apply overflow-x-hidden pt-[72px];
  }
  @include mobile {
    @apply pt-[55px];
  }
}

[data-aos="text-effect"] {
  transform: translatey(100%);
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    transform: translatey(0);
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  [data-aos] {
    pointer-events: auto !important;
    opacity: 1 !important;
    transform: none !important;
  }
}

.blur-cursor {
  @apply absolute z-50 invisible -left-[49px] -top-[49px] pointer-events-none;

  &,
  > div {
    @apply w-[98px] h-[98px];
  }

  > div {
    @apply transition-all duration-300  border border-solid border-bt-cursor-border rounded-[50%] overflow-hidden
    opacity-0 scale-50 invisible;
    &.test {
      @apply opacity-100 scale-100 visible;
    }

    &:after,
    &:before {
      @apply content-[''] absolute;
    }

    &:after {
      @apply w-full h-full z-[1] bg-bt-pure-white/[0.12] backdrop-blur-sm;
    }

    &:before {
      @apply w-10 h-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[2] bg-cover bg-center bg-no-repeat
      bg-[url('../assets/images/cursor-before-icon.svg')];
    }
  }
}

.show-cursor {
  @apply relative cursor-none;
  &:hover {
    .blur-cursor {
      @apply visible;
      @include mobile {
        @apply hidden;
      }

      > div {
        @apply opacity-100 scale-100 visible;
      }
    }
  }
}

ng-icon {
  svg {
    @apply max-w-full max-h-full;
  }
}

.mat-mdc-dialog-container {
  .mdc-dialog__content {
    font-family: inherit !important;
  }
}

.tags {
  @apply flex flex-wrap;

  .tag {
    @include bt-caption-s;
    @apply flex whitespace-nowrap py-2 px-2.5 mb-2.5 mr-2.5 bg-bt-pure-white rounded-5xl;
  }
}
