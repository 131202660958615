@import "../styles/typography_fluid";

.bt-btn {
  @apply border-bt-pure-white inline-flex flex-row items-center justify-center font-medium relative transition-transform duration-500 translate-y-0
  text-bt-pure-white overflow-hidden;
  @include bt-button-s;
  border-radius: get-vw(40px);
  padding: get-vw(14px) get-vw(20px);
  height: get-vw(48px);
  column-gap: get-vw(4px);

  @include until(1440px) {
    @apply gap-x-1;
  }

  @include mobile {
    @apply h-10 py-2.5 px-3.5 rounded-5xl;
  }

  &:hover {
    transform: translateY(get-vw(-4px));

    @include mobile {
      @apply translate-y-0;
    }

    .bt-btn-text {
      span {
        @apply invisible opacity-0 -translate-y-full;
        @include mobile {
          @apply visible opacity-100 translate-y-0;
        }
      }

      &:after {
        @apply visible opacity-100 translate-y-0;
        @include mobile {
          @apply invisible opacity-0 translate-y-full;
        }
      }
    }
  }

  &:disabled {
    @apply opacity-20;
  }

  &.bt-btn-light {
    @apply text-bt-matte-black border-bt-matte-black;
    border-width: get-vw(1px);

    @include until(1440px) {
      @apply border;
    }

    .spin-btn {
      @apply bg-bt-pure-white;
      .spin {
        @apply border-bt-matte-black;
        &:after {
          @apply bg-bt-pure-white;
        }
      }
    }

    .success-btn {
      @apply bg-bt-pure-white text-bt-matte-black;
    }
  }

  &.bt-btn-sm {
    @include bt-button-xs;
    height: get-vw(33px);
    padding: get-vw(6px) get-vw(8px);
    border-radius: get-vw(100px);

    @include mobile {
      @apply h-[26px] rounded-[100px];
    }
  }

  &.backButton {
    @apply bg-bt-matte-black text-bt-pure-white border-bt-matte-black;
  }

  &.whiteButton {
    @apply bg-bt-pure-white text-bt-matte-black;
  }

  &.borderButton {
    @apply bg-transparent text-bt-matte-black border-solid border-bt-matte-black;
    border-width: get-vw(1px);

    @include until(1440px) {
      @apply border;
    }
  }

  &.loading {
    .spin-btn {
      @apply cursor-not-allowed opacity-100 visible;
    }
  }

  &.success {
    .success-btn {
      @apply cursor-not-allowed opacity-100 visible;
    }
  }

  .spin-btn,
  .success-btn {
    @apply absolute w-full h-full flex justify-center items-center bg-bt-matte-black transition-all duration-300 opacity-0 invisible
    pointer-events-none;
    border-radius: get-vw(40px);

    @include until(1440px) {
      @apply rounded-5xl;
    }
  }

  .spin-btn {
    @apply z-10;
    .spin {
      @apply rounded-full relative border-solid border-bt-pure-white animate-spin;
      border-width: get-vw(1px);
      width: get-vw(20px);
      height: get-vw(20px);

      @include until(1440px) {
        @apply border w-5 h-5;
      }

      &:after {
        @apply content-[''] absolute bg-bt-matte-black z-10;
        right: get-vw(-1px);
        top: get-vw(-1px);
        width: get-vw(10px);
        height: get-vw(10px);

        @include until(1440px) {
          @apply -right-[1px] -top-[1px] w-2.5 h-2.5;
        }
      }
    }
  }

  .success-btn {
    @apply z-20 text-bt-pure-white;
  }

  &-text {
    @apply relative;
    span,
    &:after {
      @apply duration-500 transition-all;
    }

    span {
      @apply visible opacity-100 translate-y-0 block;
    }

    &:after {
      @apply content-[attr(data-hvr)] absolute w-full top-0 left-0 right-0 bottom-0 opacity-0 invisible translate-y-full;
    }
  }
}
