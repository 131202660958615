$bt-font-weight-regular: 400;
$bt-font-weight-medium: 500;
$bt-font-size-72: 72px;
$bt-font-size-54: 54px;
$bt-font-size-36: 36px;
$bt-font-size-32: 32px;
$bt-font-size-28: 28px;
$bt-font-size-26: 26px;
$bt-font-size-24: 24px;
$bt-font-size-20: 20px;
$bt-font-size-19: 19px;
$bt-font-size-18: 18px;
$bt-font-size-16: 16px;
$bt-font-size-15: 15px;
$bt-font-size-14: 14px;
$bt-font-size-13: 13px;

@mixin bt-title-xxl {
    font-size: get-vw($bt-font-size-72);
    font-weight: $bt-font-weight-medium;
    line-height: 90%;
    @include mobile {
        font-size: $bt-font-size-36;
        line-height: 100%;
    }
}
@mixin bt-title-xl {
    font-size: get-vw($bt-font-size-54);
    font-weight: $bt-font-weight-medium;
    line-height: 90%;
    @include mobile {
        font-size: $bt-font-size-32;
        line-height: 95%;
    }
}
@mixin bt-title-l {
    font-size: get-vw($bt-font-size-36);
    font-weight: $bt-font-weight-medium;
    line-height: 115%;
    @include mobile {
        font-size: $bt-font-size-26 !important;
    }
}
@mixin bt-title-m {
    font-size: get-vw($bt-font-size-28);
    font-weight: $bt-font-weight-medium;
    line-height: 105%;
    @include mobile {
        font-size: $bt-font-size-24;
    }
}
@mixin bt-title-s {
    font-size: get-vw($bt-font-size-24);
    font-weight: $bt-font-weight-medium;
    line-height: 105%;
    @include mobile {
        font-size: $bt-font-size-19;
    }
}
@mixin bt-subtitle-l-regular {
    font-size: get-vw($bt-font-size-20);
    font-weight: $bt-font-weight-regular;
    line-height: 120%;
    @include until(1440px) {
        font-size: $bt-font-size-20;
    }
}
@mixin bt-subtitle-l-medium {
    font-size: get-vw($bt-font-size-20);
    font-weight: $bt-font-weight-medium;
    line-height: 120%;
    @include until(1440px) {
        font-size: $bt-font-size-20;
    }
}
@mixin bt-subtitle-s-regular {
    font-size: get-vw($bt-font-size-18);
    font-weight: $bt-font-weight-regular;
    line-height: 130%;
    @include until(1440px) {
        font-size: $bt-font-size-18;
    }
}
@mixin bt-subtitle-s-medium {
    font-size: get-vw($bt-font-size-18);
    font-weight: $bt-font-weight-medium;
    line-height: 130%;
    @include until(1440px) {
        font-size: $bt-font-size-18;
    }
    @include mobile {
        font-size: $bt-font-size-16;
    }
}
@mixin bt-caption {
    font-size: get-vw($bt-font-size-15);
    font-weight: $bt-font-weight-medium;
    line-height: 150%;
    @include until(1440px) {
        font-size: $bt-font-size-15;
    }
}
@mixin bt-caption-s {
    font-size: get-vw($bt-font-size-14);
    font-weight: $bt-font-weight-medium;
    line-height: normal;
    @include until(1440px) {
        font-size: $bt-font-size-14;
    }
    @include mobile {
        font-size: $bt-font-size-13;
    }
}
@mixin bt-p-s-medium {
    font-size: get-vw($bt-font-size-15);
    font-weight: $bt-font-weight-medium;
    line-height: 140%;
    @include until(1440px) {
        font-size: $bt-font-size-15;
    }
    @include mobile {
        font-size: $bt-font-size-15;
        line-height: 130%;
    }
}
@mixin bt-p-s-regular {
    font-size: get-vw($bt-font-size-15);
    font-weight: $bt-font-weight-regular;
    line-height: 140%;
    @include until(1440px) {
        font-size: $bt-font-size-15;
    }
}
@mixin bt-p-m-medium {
    font-size: get-vw($bt-font-size-24);
    font-weight: $bt-font-weight-medium;
    line-height: 130%;
    @include mobile {
        font-size: $bt-font-size-18;
        line-height: 140%;
    }
}
@mixin bt-button-s {
    font-size: get-vw($bt-font-size-16);
    font-weight: $bt-font-weight-medium;
    line-height: normal;
    @include until(1440px) {
        font-size: $bt-font-size-16;
    }
    @include mobile {
        font-size: $bt-font-size-14;
    }
}
@mixin bt-button-xs {
    font-size: get-vw($bt-font-size-14);
    font-weight: $bt-font-weight-regular;
    line-height: normal;
    @include until(1440px) {
        font-size: $bt-font-size-14;
    }
    @include mobile {
        font-size: $bt-font-size-13;
        font-weight: $bt-font-weight-medium;
    }
}

.bt-title-xxl {
    @include bt-title-xxl;
}

.bt-title-xl {
    @include bt-title-xl;
}

.bt-title-l {
    @include bt-title-l;
}

.bt-title-m {
    @include bt-title-m;
}

.bt-title-s {
    @include bt-title-s;
}

.bt-subtitle-l-regular {
    @include bt-subtitle-l-regular;
}

.bt-subtitle-l-medium {
    @include bt-subtitle-l-medium;
}

.bt-subtitle-s-regular {
    @include bt-subtitle-s-regular;
}

.bt-subtitle-s-medium {
    @include bt-subtitle-s-medium;
}

.bt-caption {
    @include bt-caption;
}

.bt-caption-s {
    @include bt-caption-s;
}

.bt-p-s-medium {
    @include bt-p-s-medium;
}

.bt-p-s-regular {
    @include bt-p-s-regular;
}

.bt-p-m-medium {
    @include bt-p-m-medium;
}

.bt-button-s {
    @include bt-button-s;
}

.bt-button-xs {
    @include bt-button-xs;
}
